import {
  GP_ACCESS_VERIFICATION_COOKIE,
  GP_ACCESS_TOKEN_COOKIE,
} from '~/plugins/auth/constants';
import {
  discovery,
  buildEndSessionUrl,
  randomState,
  buildAuthorizationUrl,
  randomPKCECodeVerifier,
  calculatePKCECodeChallenge,
} from 'openid-client';

const useAuth = () => {
  const route = useRoute();
  const { $auth } = useNuxtApp();
  const accessTokenCookie = useCookie(GP_ACCESS_TOKEN_COOKIE);
  const i18n = useI18n();
  const config = useRuntimeConfig();
  const keycloakOIDCUrl = computed(
    () => `${config.public.auth.url}/realms/${config.public.auth.realm}`,
  );

  const login = async (redirectPath?: string, provider?: string) => {
    const toRedirectPath =
      redirectPath != null && typeof redirectPath === 'string'
        ? redirectPath
        : route.fullPath;

    const codeVerifier: string = randomPKCECodeVerifier();
    const codeChallenge: string =
      await calculatePKCECodeChallenge(codeVerifier);

    const state = randomState();
    const verificationCookie = useCookie(GP_ACCESS_VERIFICATION_COOKIE);
    verificationCookie.value = {
      state,
      code_verifier: codeVerifier,
    };

    const keycloakIssuer = await discovery(
      new URL(keycloakOIDCUrl.value),
      config.public.auth.clientId,
    );

    const parameters: Record<string, string> = {
      state,
      redirect_uri: `${config.public.websiteUrl}${toRedirectPath}`,
      scope: 'openid email profile gportal',
      code_challenge: codeChallenge,
      code_challenge_method: 'S256',
    };

    if (provider != null && provider.length > 0) {
      parameters.kc_idp_hint = provider;
    }

    const redirectTo: URL = buildAuthorizationUrl(keycloakIssuer, parameters);
    navigateTo(redirectTo.toString(), {
      external: true,
    });
  };

  const logout = async (redirectPath?: string) => {
    let toRedirectPath = null;
    if (route.meta.auth) {
      // when logging out from a auth protected page, redirect to home page
      toRedirectPath = `/${i18n.locale.value}`;
    } else if (redirectPath != null && typeof redirectPath === 'string') {
      // customized logout path
      toRedirectPath = redirectPath;
    } else {
      // stay on page after logging out
      toRedirectPath = route.fullPath;
    }

    const keycloakIssuer = await discovery(
      new URL(keycloakOIDCUrl.value),
      config.public.auth.clientId,
    );

    const url = await buildEndSessionUrl(keycloakIssuer, {
      post_logout_redirect_uri: `${config.public.websiteUrl}${toRedirectPath}`,
      id_token_hint: $auth.storedTokenSet.id_token,
    });

    // force removal of access token from the cookies, so after the redirect from keycloak to nuxt,
    // we do not want the server to fetch the user data and sets the store
    accessTokenCookie.value = undefined;
    $auth.clearTokenStorage();

    navigateTo(url.toString(), {
      external: true,
    });
  };

  return {
    login,
    logout,
  };
};

export default useAuth;
